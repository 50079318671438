@import 'styles/popup';
@import 'styles/colors';

.image_refiner_modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-top: 62px;
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-y: auto;
  flex-flow: column nowrap;
  background: $dark-background-third;

  .image_refiner_popup_inner {
    position: relative;
    width: 100%;
    height: 100%;
    width: 1240px;
    border: none;
    padding-top: 8px;

    .close_button {
      position: absolute;
      top: 16px;
      right: 0px;
    }
  }
}

.navigate_arrows {
  align-self: center;
  display: flex;
  margin-top: auto;
  align-self: flex-start;
  gap: 16px;

  .navigate_arrow {
    user-select: none;
    cursor: pointer;

    width: 40px;
    height: 40px;
    color: $dark-primary-text;

    &.disabled {
      color: $dark-secondary-text;
      cursor: auto;
    }

    &.navigate_arrow_left {
      transform: rotate(180deg);
    }
  }
}

.edit_form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.edit_form_title {
  font-size: 16px;
  color: $dark-primary-text;
  font-weight: 500;
}
