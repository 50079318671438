@import 'styles/mixins';
@import 'styles/colors';

.header_navigate-buttons-container {
  display: flex;
  gap: 20px;
  flex: 1;
  height: 62px;
  margin-left: 48px;
  align-items: center;
}

.header__button_icon {
  align-self: center;
}

.logout-button {
  margin-left: auto;
  border-color: $dark-primary-text;
}
