@import 'styles/colors';

.full_screen_modal_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  padding: 24px 180px 48px;
  background-color: $black;
  z-index: 2;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }

  .full_screen_modal {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .action_block {
      position: absolute;
      height: 100%;
      left: -100px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;

      .action_title {
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        color: $dark-primary-text;
      }
    }

    .image_container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .current_image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .close_button {
      position: absolute;
      top: 0px;
      right: -100px;
    }
  }
}
