@import 'styles/mixins';

.lds-ring {
  display: inline-block;
  position: relative;
  @include adaptive-size(width, 80, 41, 2500, 1280);
  @include adaptive-size(height, 80, 41, 2500, 1280);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  @include adaptive-size(width, 64, 33, 2500, 1280);
  @include adaptive-size(height, 64, 33, 2500, 1280);
  @include adaptive-size(border-width, 8, 4, 2500, 1280);
  @include adaptive-size(margin, 10, 5, 2500, 1280);
  border-style: solid;
  border-color: #b5b5b5;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #b5b5b5 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  .lds-ring {
    width: 41px;
    height: 41px;
  }
  .lds-ring div {
    width: 33px;
    height: 33px;
    border-width: 4px;
    margin: 5px;
  }
}

@media (min-width: 2500px) {
  .lds-ring {
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    width: 64px;
    height: 64px;
    border-width: 8px;
    margin: 10px;
  }
}
