@import 'styles/colors';

.title-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    color: $dark-primary-text;
    align-self: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .dark {
    color: $dark-third-text;
  }
}
