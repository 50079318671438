@import 'styles/mixins';
@import 'styles/colors';

.primary-button {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 72px;
  width: 285px;
  font-family: 'Inter';
  color: $dark-primary-button-active-text;
  background: $dark-primary-button-active;
  font-weight: 600;
  font-size: 36px;
  // background: linear-gradient(0.25turn, #560099, #0d4bef, #0058ff);
  &:disabled {
    color: $dark-text-inactive;
    background: $dark-primary-button-disabled;
  }
  &:hover:enabled {
    cursor: pointer;
    background: $dark-primary-text;
    color: $black;
  }
  &:active:enabled {
    background: $dark-primary-button-active;
  }
}
