$dark-primary-button: #0058ff;
$dark-primary-text: #fff;
$dark-secondary-text: #7e7e7e;
$dark-third-text: #bcc1a1;
$dark-primary-background: #14110f;
$dark-primary-button-focused: #367bff;
$dark-primary-button-active: #8f8f8d;
$dark-secondary-button-focused: #1e1e1f;
$dark-secondary-button-active: #19191a;
$dark-text-inactive: #8f8f8d;
$dark-primary-button-disabled: #5d5d5d;
$dark-background-secondary: #1c1e23;
$dark-placeholder: #686868;
$dark-border: #8f8f8f;
$dark-text-secondary: #7e7e7e;
$dark-badge-success: #02b88d;
$dark-primary-button-active-text: #272727;
$dark-select-border: #555555;
$dark-background-third: #171715;
$dark-error-text: #f44336;

$new-main: #878a00;

$black: #000000;
$skyline-blue: #00c2ff;
