@import 'styles/colors';

.container {
  background: $dark-primary-background;
  display: flex;
  width: 100%;
  min-width: 1260px;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
}
