@import 'styles/colors';

.generate-lighting-selectors_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.generate-lighting-selectors-block_container {
  display: flex;
  gap: 24px;
  position: relative;

  .generate-description {
    position: absolute;
    width: 242px;
    right: 0px;
    top: 56px;
    color: $dark-third-text;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }
  .generate-mid-description {
    position: absolute;
    width: 242px;
    right: 266px;
    top: 56px;
    color: $dark-third-text;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }

  & > div {
    align-self: flex-start;
  }
}

.generate-lighting-selector {
  width: 242px;
}
