@import 'styles/mixins';
@import 'styles/colors';

.generate-selector-container {
  width: 100px;
}

.generate-selectors-block {
  margin: 0;
  display: grid;
  grid-template-columns: 132px 132px;
  gap: 16px;
  justify-content: start;
}

.selectors-buttons-container {
  display: flex;
  gap: 12px;
}
.select {
  width: 132.5px;
}

.selectors-selected-button {
  background-color: '#1E6738';
}

.generate-select:control {
  cursor: pointer;
  padding-top: '6px';
  padding-bottom: '6px';
}

@media (max-width: 1280px) {
  .generate-selectors-block {
    gap: 10px;
  }
}

@media (min-width: 2500px) {
  .generate-selectors-block {
    gap: 20px;
  }
}
.select-block-container {
  padding-right: 16px;
  background: '#fff';
  background-color: '#fff';

  .bottom-description {
    position: absolute;
    bottom: -20px;
    left: 0px;
    color: $dark-third-text;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }

  .shaved-head-block {
    position: relative;

    .description {
      position: absolute;
      right: -15px;
      bottom: -10px;
      width: 147px;
      color: $dark-third-text;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
    }

    .generate__switch-block_title {
      font-family: Inter;
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      color: $dark-primary-text;
      margin-top: 26px;
    }
  }
}
