@import 'styles/popup';
@import 'styles/colors';

.log-in-form {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-y: auto;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(25px);
}

.log-in-form-inner {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  display: flex;
  padding: 20px 24px;
  border-radius: 10px;
  border: 1px solid $dark-border;
  background-color: $dark-background-secondary;
  width: 392px;
  height: 216px;
}

.sign-in-text {
  color: $dark-primary-text;
  align-self: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.log-in__social-network_container {
  align-self: center;
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  gap: 20px;
}

.log-in-top-bar {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.log-in-x-icon {
  width: 24px;
  height: 24px;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  cursor: pointer;
}
