@import 'styles/colors';

.log-in__social-network {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2c2e31;
  height: 40px;
  gap: 8px;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  &:hover:enabled {
    cursor: pointer;
  }
}

.log-in__social-network_text {
  color: $dark-primary-text;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.log-in__social-network_logo {
  width: 24px;
  height: 24px;
  object-fit: none;
}
