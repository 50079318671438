.generate__render-setting-buttons_container {
  display: flex;
  flex-direction: column;
  width: 133px;
  gap: 20px;
  height: 100%;
}

.generate__render-setting-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: -10px;
  line-height: 24.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #fff;
}
