@import './colors';

.Toastify__toast {
  min-height: 48px !important;
  max-height: 48px !important;
}

.Toastify__toast-theme--dark {
  border: 1px solid $dark-border;
  background: $dark-background-secondary !important;
}

.Toastify__close-button {
  width: 20px !important;
  height: 20px !important;
  margin: auto 0;
  & > svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.Toastify__toast-icon {
  background: #fff !important;
  border-radius: 50%;
}
:root {
  --toastify-icon-color-success: #02b88d !important;
}
//Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter
