@import 'styles/colors';

.banner-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.banner-column {
  height: 285px;
}

.banner-link {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: $skyline-blue;
  margin-left: 20px;
  text-decoration: underline;
}

.banner-description {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 20px;
}

.banner-diy-button {
  width: 286px;
  height: 72px;
  border-width: 2px;
  border-color: $dark-primary-text;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  margin-right: 44px;
}

.banner-text {
  font-family: Inter;
  font-size: 48px;
  font-weight: 500;
  line-height: 55.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: $skyline-blue;

  &.banner-contrast {
    color: $dark-primary-text;
  }
  &.banner-contrast-grey {
    color: #9c9c9c;
  }

  &.banner-or {
    color: #9c9c9c;
    width: 152px;
  }

  &.banner-diy {
    color: #dc68de;
    margin: 0;
    width: 330px;
    margin-left: 44px;
  }

  &.banner-finish {
    font-size: 64px;
    padding-top: 150px;
    width: 920px;
  }
  &.banner-gray {
    color: $dark-text-inactive;
  }
  &.banner-red {
    color: $dark-error-text;
  }
  &.banner-describe {
    width: 593px;
    align-self: flex-end;
  }
}
