@import 'styles/mixins';
@import 'styles/colors';

.app-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  width: 739px;
  color: $dark-primary-text;
  font-family: 'Inter';
}

.header {
  align-items: center;
  display: flex;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  height: 62px;
  box-shadow: 0px 1px 0px 0px #2a2a2c;
}
