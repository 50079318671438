@import 'styles/colors';

.first-time-generation__physical-details-container {
  display: flex;
  flex-direction: row;
  margin-left: 1px;
  margin-top: 1px;
  gap: 16px;
  width: calc(100% - 1px);
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 500px;
  left: 0px;

  &.active {
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-name: show;
  }

  &.hide {
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-name: hide;
  }

  .presets-container {
    display: flex;
    gap: 16px;
    flex: 1;

    .first-time-generation__physical-details-preset {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .select-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 6px;
        border-radius: 4px;
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.03em;
        text-align: left;
        color: $dark-primary-button-active;
        border: 2px solid $dark-primary-button-active;
        cursor: pointer;
      }
    }
  }
}

.first-time-generation__physical-details-close-btn {
  width: 40px;
  height: 40px;
  border-radius: 0px 4px 0px 0px;
  border-color: #848484;
  border-width: 0.5px 0.5px 0px 0px;
  border-style: solid;
  cursor: pointer;
  margin-left: auto;
}

@keyframes show {
  from {
    top: 500px;
    opacity: 0;
    pointer-events: none;
  }

  to {
    top: 0px;
    opacity: 1;
    pointer-events: auto;
  }
}

@keyframes hide {
  from {
    top: 0px;
    opacity: 1;
    pointer-events: auto;
  }

  to {
    top: 500px;
    opacity: 0;
    pointer-events: none;
  }
}
