@import 'styles/mixins';
@import 'styles/colors';

.dashboard__history-container {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  flex: 1;
  min-height: 100%;
}

.dashboard__load-more-btn {
  align-self: center;
  margin-top: 20px;
  width: 220px;
}

.dashboard__history-title {
  color: $dark-primary-text;
  font-size: 20px;
  font-weight: 400;
}

.dashboard__history-images_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 193px;
  align-items: center;
  gap: 24px;
}

.dashboard__images-block {
  width: 100%;
}

.dashboard__images-list {
  display: flex;
  flex-direction: column;

  @include adaptive-size(gap, 50, 20, 2500, 1280);
}

.dashboard__generation-info-container {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid $dark-border;
}

.dashboard__info-values-container {
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 100px repeat(4, calc((100% - 200px - (20px * 5)) / 4)) 100px;
}

.dashboard__history_action_buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info_column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: $dark-primary-text;

  & > span {
    color: $dark-primary-text;
    font-size: 14px;
    font-weight: 500;
  }
}

.row_title {
  color: #8f8f8d;
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard__timestamp-text {
  color: #cdcdcd;
  text-transform: capitalize;
}

.dashboard__action_button {
  // in order to fix box-sizing
  border-color: #fff;
  margin-right: -1px;
}
