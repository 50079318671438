@import 'styles/colors';

.edit_generated__images_container {
  gap: 16px;
  flex-direction: column;
  display: flex;

  .edit_generated__original_image_block {
    justify-content: space-between;
    align-items: flex-end;
  }

  .upscaled_image_block {
    height: 484px;
    justify-content: center;
  }

  .edit_generated__original_image_block {
    display: flex;
    flex: 1;
    gap: 20px;
    width: 100%;
  }
  .upscaled_image_block {
    display: flex;
    flex: 1;
    gap: 20px;
    max-width: 484px;
    width: 100%;
  }

  .edit_generated__original_image_block,
  .upscaled_image_block {
    .image_to_refine,
    .upscaled_image {
      width: auto;
      display: flex;
      border-radius: 4px;

      &.big_image {
        max-width: 484px;
        max-height: 484px;
        width: 100%;
        object-fit: contain;
        object-position: bottom;
      }

      &.small_image {
        aspect-ratio: 1/1;
        max-height: 50%;
        width: 242px;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }

  .button_block {
    display: flex;
    gap: 20px;

    .unupscaled_button_block {
      width: 484px;
      display: flex;
      gap: 20px;

      &.full {
        width: 50%;

        .download_btn {
          flex: 1;
        }
      }
    }

    .upscale_button {
      font-family: Inter;
      font-weight: 600;
      font-size: 20px;
      width: 232px;
    }

    .download_btn {
      min-width: 242px;
      height: 72px;
      color: $dark-border;
      font-weight: 500;
      font-size: 20px;

      &.full {
        width: 50%;
      }
    }
  }
}

.upscaled-images-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 48px;
  font-family: Inter;
}

.upscaled-images-title {
  color: #fff;
  margin-bottom: 6px;
  align-self: start;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.upscaled-images-title {
  color: $dark-primary-text;
  font-size: 16px;
}
