@import 'styles/mixins';
@import 'styles/colors';

.log-in__banner-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-bottom: 20px;
  width: 1240px;
}

.log-in__banner-images_container {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  width: 1200px;
  height: 270px;
}

.log-in__banner-image {
  margin-right: 20px;
}

.log-in__banner-description {
  font-family: Inter;
  font-size: 64px;
  font-weight: 500;
  line-height: 73.6px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #bcc1a1;
  &-contrast {
    color: $dark-primary-text;
  }
}

.log-in__banner-header-text {
  font-weight: 300;
  font-size: 20px;
  line-height: 24.2px;
  color: $dark-primary-text;
  margin: 20px 0px;
}

.log-in__banner-description-container {
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
  width: 1200px;
}
