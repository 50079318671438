@import 'styles/mixins';
@import 'styles/colors';

.navigation-button {
  display: flex;
  color: $dark-text-secondary;
  align-items: center;
  height: 100%;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-bottom: 2px transparent solid;
  cursor: pointer;
  &:disabled {
    cursor: auto;
    border-bottom: 2px $new-main solid;
    color: $dark-primary-text;
  }
}

.navigation-button_logo {
  height: 24px;
  width: 24px;
  display: flex;
}
