@import 'styles/mixins';
@import 'styles/colors';

.react-select-container {
  align-self: center;

  .react-select__control {
    font-family: Inter;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border: 1px $dark-select-border solid;
    background: transparent;
    display: flex;
    border-radius: 8px;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    gap: 2px;
    align-self: stretch;
  }
  .react-select__placeholder {
    margin: 0;
    color: $dark-primary-text;
  }
  .react-select__value-container {
    padding: 0;
  }
  .react-select__indicators {
    width: 20px;
    justify-content: center;
  }
  .react-select__indicator {
    padding: 0;
  }
  .react-select__single-value {
    color: #fff;
  }
  .react-select__menu {
    font-size: 14px;
    font-weight: 500;
    color: $dark-primary-text;
    background: $dark-background-secondary;
  }
  .react-select__menu-list {
    padding: 2px;
    gap: 2px;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      background: $dark-background-secondary;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      padding: 1px;
      background: $dark-background-secondary;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #2c2e31;
    }
  }
  .react-select__option--is-focused {
    background: $dark-background-secondary;
  }
  .react-select__option {
    border-radius: 2px;
    &:hover {
      background: #2c2e31;
    }
  }
  .react-select__option--is-selected {
    background: #2c2e31;
  }
}

.select-arrow {
  width: 13.33px;
}
