@import 'styles/colors';

.first-time-generation__more-container {
  display: flex;
  height: 310px;
  flex-direction: row;
  margin-left: 1px;
  margin-top: 1px;
  gap: 16px;
  width: calc(100% - 1px);
  pointer-events: none;
  opacity: 0;
  position: absolute;
  left: 0px;

  &.active {
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-name: show;
  }

  &.hide {
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-name: hide;
  }
  .buttons {
    display: flex;
    margin-left: 202px;
    flex-direction: row;
    align-items: flex-end;
    margin-top: auto;
    .finish {
      font-family: Inter;
      width: 170px;
      height: 72px;
      font-size: 22px;
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 244px;
  }
  .inspire-title {
    color: #bcc1a1;
    font-family: Inter;
    font-weight: 500;
    font-size: 22px;
    line-height: 24.6px;

    .white {
      color: $dark-primary-text;
    }

    .red {
      color: $dark-error-text;
    }
    .dark {
      color: $dark-third-text;
    }
  }
}
