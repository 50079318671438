@import 'styles/colors';

.variations-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  width: 132px;
  font-family: 'Inter';
  font-weight: 500;
  padding: 9px 12px;
  border-radius: 8px;
  line-height: 22px;
  border: 1px solid $dark-border;
  color: $dark-primary-text;
  border: 1px solid $dark-border;

  &:disabled {
    cursor: auto;
  }

  &.active {
    border: 1px $new-main solid;
  }
}

.variations-button-outer-circle {
  border: 1px $new-main solid;
  border-radius: 100px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-right: 12px;
}

.variations-button-inner-circle {
  background: $new-main;
  border-radius: 100px;
  width: 15px;
  height: 15px;
}
