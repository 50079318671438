.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-y: auto;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
}

.popup-inner {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  display: flex;
  background-color: #224f93;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
