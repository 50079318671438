@import 'styles/mixins';
@import 'styles/colors';

.input_wrapper {
  position: relative;
  font-family: Inter;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
}

.text-input-label {
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  color: $dark-primary-text;
}

.text-input {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  background: transparent;
  border: 1px solid $dark-border;

  color: #fff;

  font-style: normal;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-input-size-counter {
  color: #fff;
  position: absolute;
  box-sizing: border-box;
  top: 25%;
  right: 2%;
  transform: translateY(-50%);
}

.text-input::placeholder {
  color: $dark-placeholder;
}

input:focus {
  outline: none;
}
