@import 'styles/colors';

.ratio-button-shape {
  background: $dark-primary-button;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
