@import 'styles/popup';

.detail_popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
}

.detail_popup_inner {
  position: absolute;
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: #224f93;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  width: 18%;
  height: 38%;
  min-width: 300px;
  min-height: 300px;
}

.image_comparison_popup_inner {
  align-items: start;
  background-color: #030406;
  aspect-ratio: 1/1;
  width: unset;
  height: 90vh;
}

.social-network:hover {
  cursor: pointer;
}

.enabled:hover:enabled {
  border-radius: 200px;
  box-shadow: 0 8px 16px rgba(94, 203, 233, 0.2);
}

.close_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
  color: #234b86;
  &:hover {
    color: #d9fd00;
  }
}

.image_comparison_block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.image_comparison_title {
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
}
.image_to_refine {
  width: 100%;
}

.download_btn {
  display: block;
  margin-left: auto;
  margin-top: 30px;
}
