@import 'styles/colors';

.generate__page-tab-title {
  color: #bcc1a1;
  font-family: Inter;
  font-weight: 500;
  font-size: 64px;
  line-height: 73.6px;

  .white {
    color: $dark-primary-text;
  }

  .red {
    color: $dark-error-text;
  }
}
