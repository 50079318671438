.select-modal-container {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overflow-y: auto;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .select-modal-inner {
    margin-right: auto;
    margin-left: auto;
    width: 1240px;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    .select-modal-inner-container {
      margin-top: 386px;
      margin-bottom: auto;
      flex-direction: column;
      display: flex;
      gap: 12px;
      padding: 8px 8px 16px 16px;
      border-radius: 4px;
      background-color: #171715;
      width: 900px;

      .presets-container {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(6, 120px);
      }
    }
  }
}
