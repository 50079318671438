@import 'styles/popup';
@import 'styles/colors';

.image_refiner_popup_inner {
  gap: 24px;
  height: unset;
  width: 600px;
  height: 480px;
}

.edit_image_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image_center_container {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
}

.centered_image_wr {
  height: 100%;
  height: 348px;
  display: flex;
  justify-content: center;
}

.image_to_refine {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid $dark-border;
  border-radius: 10px;
  object-fit: contain;
}

.download_btn {
  width: 100%;
  min-height: 40px;
}

.image_preview_wr {
  width: 100%;
  position: relative;
}

.navigate_arrow {
  user-select: none;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);

  color: $dark-primary-text;

  &.disabled {
    cursor: auto;
    color: $dark-secondary-text;
  }

  &.navigate_arrow_left {
    right: 0;
    transform: translateY(-50%);
  }
}
