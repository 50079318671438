@import 'styles/colors';

.preset-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;

  img {
    object-fit: cover;
    border-radius: 8px;
    aspect-ratio: 1/1;
    width: 100%;
    border: 1px solid #3a3d40;
  }

  &.show-state {
    cursor: default;
  }
}

.title {
  flex: 1;
}
