@import 'styles/mixins';

.dashboard-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #232320;
}

.dashboard-content-container {
  display: flex;
  flex: 1;
  max-width: 1240px;
  min-width: 1200px;
  padding: 24px 0px;
  gap: 80px;
  margin: 0 auto;
}
