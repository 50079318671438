@import 'styles/colors';

.generation__section-tabs_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  width: 328px;
}

.generation__section-tab {
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.57px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #575757;
  display: inline;
  margin-right: 20px;
  border-bottom: 0.5px solid #848484;
  width: 284px;
  display: inline-block;
  padding-bottom: 4px;
  padding-top: 12px;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &.active {
    color: $dark-primary-text;
  }
}

.generation__section-tab_small {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  letter-spacing: -0.03em;
  text-align: left;
  border-bottom: 0.5px solid #848484;
  width: 284px;
  color: #575757;
  display: inline-block;
  padding-bottom: 4px;
  padding-top: 12px;
  margin-right: 20px;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &.settings {
    color: #8f8f8d;
  }

  &.active {
    color: $dark-primary-text;
  }
}

.generation__section-tab-small {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #8f8f8d;
  border-bottom: 0.5px solid #848484;
  width: 284px;
  display: inline-block;
  padding-bottom: 4px;
  padding-top: 12px;
  margin-right: 20px;
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
}

.generation__section-tab-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  &.show_settings {
    opacity: 1;
    pointer-events: all;
  }

  &.hide_settings {
    opacity: 0;
    pointer-events: none;
  }

  .state-icon {
    padding-bottom: 8px;
    width: 24px;
  }
}
