@import './styles/reset';
@import './styles/override';

.App {
  text-align: center;
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('./assets/fonts/Inter-Regular.ttf');
}
@font-face {
  font-family: 'Inter-Light';
  src: url('./assets/fonts/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./assets/fonts/Inter-Medium.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@450&display=swap');

* {
  font-family: Inter;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
