@import 'styles/popup';
@import 'styles/colors';

.prompt_description_popup_inner {
  height: unset;
  width: 440px;
}

.prompts_block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
