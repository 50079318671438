@import 'styles/mixins';

.log-in__button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 16px;
  @include adaptive-size(padding-bottom, 50, 15, 2500, 1280);
  width: 1160px;
  align-self: center;
}
