@import 'styles/mixins';
@import 'styles/colors';

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 40px;
  font-family: 'Inter';
  font-weight: 500;
  padding: 12px 16px;
  border-radius: 4px;
  line-height: 22px;
  border: 1px solid $dark-border;
  color: $dark-primary-text;

  &:disabled {
    color: $dark-text-inactive;
    border: 1px solid $dark-primary-button-disabled;
  }
  &:hover:enabled {
    cursor: pointer;
    background: $dark-secondary-button-focused;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  &:active:enabled {
    background: $dark-secondary-button-active;
  }
}
