@import 'styles/colors';

.banner-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 1240px;
  justify-content: space-between;
}

.banner-header {
  font-weight: 300;
  font-size: 20px;
  line-height: 24.2px;
  color: $dark-primary-text;
  margin: 20px 0;
}

.banner-image {
  height: 285px;
  width: 285px;
}

.banner-row-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.banner-row {
  height: 312px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
}
