@import 'styles/mixins';

.generated-block-container {
  padding: 16px 0;
  height: 260px;
  gap: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.generate__generated-image_container {
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  width: auto;
  height: 100%;
}

.generate__generated-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.generate__generation-loading-item {
  border: 1.5px #b5b5b5 solid;
  border-radius: 4px;
  display: flex;
  border: 1px solid #3a3d40;
  height: inherit;
  width: 100%;
}
