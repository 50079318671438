@import 'styles/colors';

.generate__generation_container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.Toastify__toast-container {
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
}

/* General toast styling */
.Toastify__toast {
  font-family: 'Inter', sans-serif;
  width: fit-content !important;
}

.generate__generation-section_container {
  position: relative;
  display: flex;
  width: 1240px;
  gap: 70px;

  .first-login-arrow {
    position: absolute;
    top: 200px;
    left: 290px;
  }
  .ftu-images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .image {
      width: 170px;
      height: 170px;
      border-radius: 8px;
    }
  }

  .tabs-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.hide {
      display: none;
    }
  }

  .inspire-container {
    position: absolute;
    flex-direction: column;
    bottom: 7.5px;
    z-index: 8;
    display: flex;
    left: 660px;
    height: 280px;
    gap: 20px;
    justify-content: flex-end;
  }
  .inspire-image {
    width: 170px;
    height: 170px;
  }
  .inspire-primary {
    height: 72px;
    width: 170px;
    font-size: 22px;
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;

    &:disabled {
      color: $dark-primary-button-disabled;
    }
  }
  .inspire-secondary {
    height: 72px;
    width: 170px;
    font-size: 22px;
    color: $dark-border;
    font-weight: 500;
    font-size: 22px;
    font-family: Inter;
  }
}
