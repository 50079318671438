@import 'styles/mixins';
@import 'styles/colors';

.dashboard-account-info-container {
  font-family: Inter;
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 10px;
  width: 240px;
  height: 396px;
}

.user-info-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.account-info-title {
  color: $dark-primary-text;
  font-size: 20px;
  font-weight: 400;
}

.info_block_rows {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.info_block {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  gap: 2px;

  &_title {
    color: $dark-secondary-text;
  }

  &_description {
    margin-top: 12px;
    color: $dark-primary-text;
  }
}
.account-info-text {
  color: #cdcdcd;
  margin: 0;
  font-family: Inter-Regular;
  @include adaptive-size(font-size, 30, 15, 2500, 1280);
}

.manage-account-button {
  margin-top: 24px;
  width: 100%;
  min-height: 72px;
  font-size: 20px;
}
