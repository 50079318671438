@import 'styles/colors';

.edit_image_block {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  font-family: Inter;

  .edit_generated__action_block {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .edit_generated__prompts_block {
      flex-direction: column;
      display: flex;
      gap: 16px;
      padding-top: 120px;
      justify-content: flex-end;

      &.hide_alfa {
        justify-content: flex-end;
      }

      .edit_inputs_block {
        flex-direction: column;
        display: flex;
        gap: 16px;

        & > div {
          flex: unset;
        }
      }

      .edit_action_btn {
        color: $dark-border;
        width: 285px;
        height: 72px;
        font-weight: 500;
        font-size: 20px;
        font-family: Inter;
      }
    }
  }
}
