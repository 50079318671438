@import 'styles/colors';

.generate-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 840px;
  align-self: flex-end;
  flex: 1;
  height: 100%;
  padding-bottom: 8px;
  gap: 45px;

  .actions-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    flex: 1;

    .description-container {
      display: flex;
      justify-self: flex-start;
      margin-bottom: auto;
      flex-direction: column;
      gap: 16px;
      margin-top: 20px;
      width: 244px;
    }
    .description-title {
      color: #bcc1a1;
      font-family: Inter;
      font-weight: 500;

      font-size: 22px;
      line-height: 24.6px;

      .white {
        color: $dark-primary-text;
      }

      .red {
        color: $dark-error-text;
      }
      .dark {
        color: $dark-third-text;
      }
    }

    .upscale-description {
      font-family: Inter;
      font-size: 36px;
      font-weight: 300;
      line-height: 41.4px;
      letter-spacing: -0.03em;
      padding-top: 42px;
      width: 304px;
      margin-left: 153px;
      color: $dark-third-text;

      &.after-reset {
        width: 455px;
      }
    }
  }
}

.generate-block-inputs {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  .buttons_block {
    gap: 30px;
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;
  }
}

.generate-block-inputs-text-input {
  margin-bottom: 24px;
}

.generate-block-input-check-icon_container {
  margin-left: -34px;
  margin-right: 10px;
  margin-top: 40px;
}

.generate-block-edit-settings {
  width: 280px;
  height: 72px;
  align-self: flex-end;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  color: $dark-primary-button-active;
  border-color: $dark-primary-button-active;
  cursor: pointer;
}

.generate-block-generate-button {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: center;
  color: $dark-primary-button-active-text;
}
