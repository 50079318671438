.download_button_default {
  width: 60px;
  color: #234b86;
  img {
    width: 100%;
  }
}

.download_button_default:hover:enabled {
  color: #d9fd00;
  cursor: pointer;
}
